// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.me-fullscreen {
  transform: translateY(0);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/syndicated-layout/syndicated-header/syndicated-header.component.scss"],"names":[],"mappings":"AACA;EACI,wBAAA;AAAJ","sourcesContent":["// Fix for header over fullscreen Media Engine\r\n.me-fullscreen {\r\n    transform: translateY(0);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
